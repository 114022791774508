/** @format */

import { TableKey } from '@common/application/enums/TableKey';
import useTableSettings from '@common/application/hooks/useTableSettings/useTableSettings';
import { isEqual } from 'lodash';
import { useEffect, useReducer } from 'react';

function useTableSorting(tableKey: TableKey, search: string) {
  const { defaultSort, isLoaded } = useTableSettings(tableKey);
  const reducer = (state: Record<string, 'desc' | 'asc'>, newState) => {
    //If a nullish value is passed then return default
    // This is useful for when sorting is toggled off and there is no isSorted column to pass to the reducer
    const newVal = !!search.length ? { _score: 'desc' } : defaultSort;
    return newState ?? newVal;
  };

  // const [sort, setSort] = useState(defaultSort);
  const [sort, setSort] = useReducer(reducer, defaultSort);
  const [previousSort, setPreviousSort] = useReducer(reducer, defaultSort);

  useEffect(() => {
    if (search.length) {
      if (isEqual(sort, { _score: 'desc' })) return;
      setPreviousSort(sort);
      setSort({ _score: 'desc' });
    }
    if (!search.length) {
      setSort(previousSort);
    }
  }, [search]);

  return [sort, setSort];
}

export default useTableSorting;
/** @format */

/** @format */

import styled from '@emotion/styled';
import React from 'react';

const StyledSettingWrapper = styled.div`
  h2 {
    text-align: center;
  }
`;

function SettingWrapper(props) {
  return <StyledSettingWrapper>{props.children}</StyledSettingWrapper>;
}

export default SettingWrapper;

/** @format */

const useSortableColumns = () => {
  return {
    id: 'id',
    name: 'name.keyword',
    accountNumber: 'accountNumber.keyword',
    nationalAccountNumber: 'nationalAccountNumber.keyword',
    createdAt: 'createdAt',
    updatedAt: 'updatedAt',
    deletedAt: 'deletedAt',
    erpId: 'erpId.keyword',
    realm: 'realm.keyword',
    brand: 'brand.keyword',
    company: 'company.keyword',
    role: 'role.keyword',
    siteName: 'siteName.keyword',
    helpTypeId: 'helpTypeId.keyword',
    title: 'title.keyword',
    accountName: 'accountName.keyword',
    siteAccountNumber: 'siteAccountNumber.keyword',
    dateTime: 'dateTime',
  };
};

export default useSortableColumns;

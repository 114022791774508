/** @format */

import { useMemo } from 'react';
import Text from '../Text';
import { IDateTimeTableCellFactory } from './IDateTimeTableCellFactory';

const DateTimeTableCellFactory: IDateTimeTableCellFactory = function (Cell) {
  const dateTime = useMemo(() => new Date(Cell.value), [Cell.value]);

  return <span>{Cell.value ? <Text.Time dateTime={dateTime} /> : null}</span>;
};

export default DateTimeTableCellFactory;

/** @format */

import Button from '@atoms/Button';
import { css } from '@emotion/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

interface SettingsButtonsProps {
  onSave(): void;
  onCancel(): void;
  onResetToDefault(): void;
}

function SettingsButtons(props: SettingsButtonsProps) {
  return (
    <div
      css={css`
        width: 100%;
        margin-top: 16px;
        display: flex;
        gap: 8px;
        justify-content: center;
      `}
    >
      <Button onClick={() => props.onSave()} primary>
        <FormattedMessage defaultMessage="Save" description="Toolbar file menu save document text" />{' '}
      </Button>
      <Button onClick={() => props.onCancel()}>
        <FormattedMessage defaultMessage="Cancel" description="Cancel button text" />
      </Button>
      <Button onClick={() => props.onResetToDefault()}>
        <FormattedMessage defaultMessage="Reset To Default" description="Reset all settings to default values" />{' '}
      </Button>
    </div>
  );
}

export default SettingsButtons;

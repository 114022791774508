/** @format */

import styled from '@emotion/styled';

const StyledOpenSpan = styled.span`
  width: fit-content;
  display: flex;
  user-select: none;
`;

export default StyledOpenSpan;

/** @format */

import { Checkbox, State } from '@atoms/Checkbox';
import { cssVars } from '@atoms/GlobalStyles';
import Select, { ISelectOption } from '@atoms/Select';
import SettingWrapper from '@atoms/SettingWrapper';
import { TableKey } from '@common/application/enums/TableKey';
import { TableConfig } from '@common/application/hooks/useDefaultSettings';
import useSortableColumns from '@common/application/hooks/useSortableColumns/useSortableColumns';
import useStrings from '@common/application/hooks/useStrings/useStrings';
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StyledTable } from '@molecules/Table/styled';
import TooltippedLabel from '@molecules/TooltippedLabel/TooltippedLabel';
import { LayoutGroup, motion } from 'framer-motion';
import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Item } from 'react-stately';

interface TSCProps {
  header?: EmotionJSX.Element;
  settings: TableConfig;

  setSettings(setting): void;
  undoChanges(): void;
  settingsKey: TableKey;
}

const StyledTr = styled(motion.tr)`
  border-bottom: 1px solid ${cssVars.lightGrey};
  color: ${cssVars.grey};
  //background: ${cssVars.lightGrey};
  height: 50px;
  :last-of-type {
    border: none;
  }
  :hover {
    background-color: #e6f7ff;
  }
`;

const StyledTd = styled.td`
  padding: 8px;
  :nth-of-type(2) {
    width: 85%;
  }
`;

const StyledTbody = styled.tbody`
  width: 100%;
`;

const DirectionControls = styled.span`
  display: flex;
  flex-direction: column;
`;

const SortControls = styled.div`
  display: flex;
  width: 100%;
  //justify-content: center;
  gap: 4px;
  align-items: center;
  margin-block: 16px;
  > span {
    margin-inline: 8px;
    //font-weight: bold;
  }
  @media (max-width: 576px) {
    flex-direction: column;
  }
`;

const SortButton = styled(FontAwesomeIcon)<{ disabled: boolean }>`
  transition: 0.2s all ease;
  color: ${(props) => (props.disabled ? cssVars.lightGrey : 'inherit')};
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};

  &:hover {
    transform: scale(1.2);
    color: ${(props) => (props.disabled ? cssVars.lightGrey : cssVars.blue)};
  }
`;

function TableSettingsControls(props: TSCProps) {
  const [sortColumn, setSortColumn] = useState<string>();
  const [ascending, setAscending] = useState<string>();
  const { tableStrings } = useStrings();
  const sortableColumns = useSortableColumns();

  const columns = useMemo(() => {
    //Spread to copy by value not reference
    const cols = [...props.settings.columns];
    cols.sort((a, b) => {
      if (props.settings.hiddenColumns.includes(a)) return props.settings.columns.indexOf(a);
      if (props.settings.hiddenColumns.includes(b)) return -1;
      return 0;
    });
    return cols;
  }, [props.settings]);

  const onToggleHide = (column: string) => {
    const hiddenColumns = props.settings?.hiddenColumns;
    const index = hiddenColumns.indexOf(column);
    if (index > -1) {
      hiddenColumns.splice(index, 1);
    } else {
      hiddenColumns.push(column);
    }
    props.setSettings({ [props.settingsKey]: { ...props.settings, hiddenColumns } });
  };

  const onMoveDown = (column: string) => {
    const index = columns.indexOf(column);
    columns.splice(index, 1);
    columns.splice(index + 1, 0, column);
    props.setSettings({ [props.settingsKey]: { ...props.settings, columns } });
  };

  const onMoveUp = (column: string) => {
    const index = columns.indexOf(column);
    columns.splice(index, 1);
    columns.splice(index - 1, 0, column);
    props.setSettings({ [props.settingsKey]: { ...props.settings, columns } });
  };

  const onToggleAscending = (option: string) => {
    props.setSettings({ [props.settingsKey]: { ...props.settings, defaultSort: { ...props.settings.defaultSort, ascending: option === 'asc' } } });
    setAscending(option);
  };

  const onToggleSortColumn = (option: string) => {
    props.setSettings({ [props.settingsKey]: { ...props.settings, defaultSort: { ...props.settings.defaultSort, columnId: option } } });
    setSortColumn(option);
  };

  const sortOptions = useMemo(
    () => columns.filter((col) => !props.settings.hiddenColumns.includes(col) && col in sortableColumns).map((col): ISelectOption => ({ id: col, name: tableStrings[col] })),
    [columns, props.settings.hiddenColumns, sortableColumns],
  );

  const ascendingOptions = useMemo(
    (): ISelectOption[] => [
      {
        id: 'asc',
        name: (<FormattedMessage defaultMessage={'Ascending'} description="Set the sort order to be ascending e.g. A-Z or 1-10 rather than Z-A or 10-1" />) as any as string,
        // render: () => <FormattedMessage defaultMessage={'Ascending'} description="Set the sort order to be ascending e.g. A-Z or 1-10 rather than Z-A or 10-1" />,
      },
      {
        id: 'desc',
        name: (<FormattedMessage defaultMessage={'Descending'} description="Set the sort order to be ascending e.g. Z-A or 10-1 rather than A-Z or 1-10" />) as any as string,
        // render: () => <FormattedMessage defaultMessage={'Descending'} description="Set the sort order to be ascending e.g. Z-A or 10-1 rather than A-Z or 1-10" />,
      },
    ],
    [],
  );

  useEffect(() => {
    if (sortColumn && !sortOptions.map((o) => o.id).includes(sortColumn)) {
      setSortColumn(sortOptions[0].id);
    }
  }, [sortColumn, sortOptions]);

  useLayoutEffect(() => {
    const sort = props.settings.defaultSort;
    const ascendingVal = sort.ascending ? 'asc' : 'desc';
    const ascendingOption = ascendingOptions.find((o) => o.id === ascendingVal);
    setSortColumn(sort.columnId);
    setAscending(ascendingOption.id);
  }, [ascendingOptions, props.settings.defaultSort]);

  if (!sortColumn || !ascending) return null;

  return (
    <SettingWrapper>
      {props.header && <h3>{props.header}</h3>}
      <SortControls>
        <span>
          <FormattedMessage defaultMessage="Default Sort" description="The default way in which the table should be sorted (e.g. Name A-Z)" />:
        </span>
        <>
          <Select items={sortOptions} defaultSelectedKey={sortColumn} onSelectionChange={(v) => onToggleSortColumn(v as string)}>
            {sortOptions.map((sort) => (
              <Item key={sort.id}>{sort.name} </Item>
            ))}
          </Select>
          <Select items={ascendingOptions} defaultSelectedKey={ascending} onSelectionChange={(v) => onToggleAscending(v as string)}>
            {ascendingOptions.map((option) => (
              <Item key={option.id}>{option.name}</Item>
            ))}
          </Select>
        </>
      </SortControls>
      <StyledTable>
        <StyledTbody>
          <LayoutGroup>
            {columns?.map((col, idx) => (
              <StyledTr key={col} layout>
                <StyledTd>
                  {!props.settings.hiddenColumns.includes(col) && (
                    <DirectionControls>
                      <SortButton icon={['fas', 'chevron-up']} onClick={() => onMoveUp(col)} disabled={idx <= 0} />
                      <SortButton
                        icon={['fas', 'chevron-down']}
                        onClick={() => onMoveDown(col)}
                        disabled={idx >= props.settings.columns.length - props.settings.hiddenColumns.length - 1}
                      />
                    </DirectionControls>
                  )}
                </StyledTd>
                <StyledTd>{tableStrings[`${col}Tooltip`] ? <TooltippedLabel label={tableStrings[col]} tooltip={tableStrings[`${col}Tooltip`]} /> : tableStrings[col]}</StyledTd>
                {props.settings.mandatoryColumns.includes(col) ? (
                  <StyledTd />
                ) : (
                  <StyledTd>
                    <Checkbox.Switch value={props.settings?.hiddenColumns.includes(col) ? State.FALSE : State.TRUE} onToggle={() => onToggleHide(col)} />
                  </StyledTd>
                )}
              </StyledTr>
            ))}
          </LayoutGroup>
        </StyledTbody>
      </StyledTable>
    </SettingWrapper>
  );
}

export default TableSettingsControls;

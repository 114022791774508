/** @format */

import Modal from '@atoms/Modal';
import { cloneElement, ReactElement } from 'react';
import { useOverlayTrigger } from 'react-aria';
import { OverlayTriggerProps, useOverlayTriggerState } from 'react-stately';

function ModalTrigger({ label, children, ...props }: OverlayTriggerProps & { label: string; children(close): ReactElement; button: ReactElement }) {
  let state = useOverlayTriggerState(props);
  let { triggerProps, overlayProps } = useOverlayTrigger({ type: 'dialog' }, state);

  return (
    <>
      {cloneElement(props.button, { ...triggerProps, onClick: state.open })}
      {state.isOpen && (
        <Modal {...props} state={state} isDismissable>
          {cloneElement(children(state.close), overlayProps)}
        </Modal>
      )}
    </>
  );
}

export default ModalTrigger;

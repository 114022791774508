/** @format */

import { cssVars } from '@atoms/GlobalStyles';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface TableSkeletonProps {}

const spin = keyframes`
100%{
  transform: rotate(360deg);
}
`;

const StyledTableSkeleton = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  > svg {
    color: ${cssVars.lightGrey};
    height: 50px;
    animation: ${spin} 0.8s ease-in-out infinite;
  }
`;

function TablePlaceholder(props: TableSkeletonProps) {
  return (
    <StyledTableSkeleton>
      <FontAwesomeIcon icon={['fas', 'circle-notch']} />
    </StyledTableSkeleton>
  );
}

export default TablePlaceholder;

/** @format */
import useSortableColumns from '@common/application/hooks/useSortableColumns/useSortableColumns';

const useColumnSorting = (sortConfig: { columnId: string; ascending: boolean }) => {
  const sortMap = useSortableColumns();

  return { [sortMap[sortConfig.columnId]]: sortConfig.ascending ? 'asc' : 'desc' };
};

export default useColumnSorting;

/** @format */

import React, { ReactNode } from 'react';
import { StyledOpenSpan } from './styled';

interface OpenSpanProps {
  onClick?(e: React.MouseEvent<HTMLSpanElement>): void;

  children: ReactNode;
}

function OpenSpan(props: OpenSpanProps) {
  return <StyledOpenSpan {...props} />;
}

export default OpenSpan;

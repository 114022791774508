/** @format */

import { cssVars } from '@atoms/GlobalStyles';
import Input from '@atoms/Input';
import TablePlaceholder from '@atoms/TablePlaceholder';
import { TableKey } from '@common/application/enums/TableKey';
import useTableSettings from '@common/application/hooks/useTableSettings/useTableSettings';
import useThrottledCallback from '@common/application/hooks/useThrottledCallback';
import { css } from '@emotion/react';
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Table from '@molecules/Table';
import TableSettingsModalTrigger from '@organisms/TableSettingsModalTrigger';
import { useRouter } from 'next/router';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { Column, Row } from 'react-table';

interface SearchableTableProps {
  id?: string;
  title: EmotionJSX.Element;
  tableKey: TableKey;
  search: string;
  setSearch: (search: string) => void;
  columns: Column<{}>[];
  data: { payload: Record<string, unknown>[]; last_page: number; total: { value: number } };
  previousPage?: () => void;
  nextPage?: (data: { payload: Record<string, unknown>[]; last_page: number; total: { value: number } }) => void;
  pageHistory?: Record<string, unknown>[];
  loading?: boolean;
  className?: string;
  unpaginated?: boolean;
  onClickRow?: (row: Row) => void;
  header?: boolean;
  throttle?: boolean;
  hideTable?: boolean;
  // These are intended to be the state and dispatch returned from useTableSorting custom hook
  onSort?(sort: Record<string, 'asc' | 'desc'> | null): void;

  sort?: Record<string, 'asc' | 'desc'>;

  disableSorting?: boolean;

  renderRowSubComponent?(row: Row): ReactElement;

  allowRenderToCard?: boolean;
}

const StyledSearchableTable = styled.div``;

const TableTitleWrapper = styled.div<{ header?: boolean }>`
  width: 100%;
  display: flex;
  align-items: baseline;
  gap: 20px;
  color: ${cssVars.grey};
  font-size: ${(props) => (props.header ? '26pt' : '18pt')};

  > svg {
    height: ${(props) => (props.header ? '20px' : '14px')};
    color: ${cssVars.silverGrey};
    cursor: pointer;
    :hover {
      color: ${cssVars.blue};
    }
  }
`;

const TableHeader = styled.h1`
  font-weight: normal;
  margin: 0;
  font-size: inherit;
  margin-block: 12px;
  padding-top: 0;
`;

function SearchableTable(props: SearchableTableProps) {
  const { settings, setSettings, defaultSort, saveChanges, undoChanges, isLoaded } = useTableSettings(props.tableKey);
  const [input, _setInput] = useState(props.search);
  const setThrottledSearch = useThrottledCallback((value: string) => props.setSearch(value), [props.setSearch], props.throttle ? 250 : 0);
  const router = useRouter();

  useEffect(() => {
    if (router.query.hasOwnProperty('q')) {
      setInput(router.query.q as string);
    } else {
      setInput('');
    }
  }, [router.query]);

  const setInput = (value: string) => {
    _setInput(value);
    setThrottledSearch(value);
  };
  // Hide and set order of columns based on user settings
  const filteredColumns = useMemo(
    () =>
      props.columns
        .sort((a, b) => {
          return settings.columns.includes(a.id) && settings.columns.indexOf(b.id) > settings.columns.indexOf(a.id) ? -1 : 1;
        })
        .filter((col) => !settings?.hiddenColumns?.includes(col.id)),
    [props.columns, settings],
  );

  return (
    <StyledSearchableTable id={props.id}>
      <TableTitleWrapper header={props.header}>
        <TableHeader>{props.title}</TableHeader>
        <TableSettingsModalTrigger settings={settings} setSettings={setSettings} saveChanges={saveChanges} undoChanges={undoChanges} tableKey={props.tableKey} />
      </TableTitleWrapper>
      <Input.Search
        css={css`
          width: 100%;
          margin-bottom: 16px;
        `}
        value={input}
        onChange={setInput}
      />
      <div
        css={css`
          display: grid;
          grid-template-rows: ${props.hideTable ? '0fr' : '1fr'};
          transition: grid-template-rows 0.5s ease-in-out;
        `}
      >
        <div
          css={css`
            overflow: hidden;
          `}
        >
          {!isLoaded ? (
            <TablePlaceholder />
          ) : (
            <Table
              columns={filteredColumns}
              data={props.data as { payload: Record<string, unknown>[]; last_page: number; total: { value: number } }}
              pageHistory={props.pageHistory}
              nextPage={() => props.nextPage(props.data)}
              previousPage={props.previousPage}
              sort={props.sort}
              onSort={props.onSort}
              defaultSort={settings.defaultSort}
              onClickRow={props.onClickRow}
              loading={props.loading}
              renderRowSubComponent={props.renderRowSubComponent}
              disableSorting={props.disableSorting}
              allowRenderToCard={true}
              // allowRenderToCard={props.allowRenderToCard}
            />
          )}
        </div>
      </div>
    </StyledSearchableTable>
  );
}

export default SearchableTable;

/** @format */

import Dialog from '@atoms/Dialog';
import ModalTrigger from '@atoms/ModalTrigger';
import { TableKey } from '@common/application/enums/TableKey';
import { TableConfig } from '@common/application/hooks/useDefaultSettings';
import useDefaultSettings from '@common/application/hooks/useDefaultSettings/useDefaultSettings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SettingsButtons from '@molecules/SettingsButtons';
import TableSettingsControls from '@molecules/TableSettingsControls';
import React from 'react';
import { FormattedMessage } from 'react-intl';

interface TableSettingsModalProps {
  settings: TableConfig;
  setSettings(val: any): void;
  saveChanges(successCallback?: () => void): Promise<any>;
  undoChanges(): void;
  tableKey: TableKey;
}

function TableSettingsModalTrigger(props: TableSettingsModalProps) {
  const defaultSettings = useDefaultSettings().tableSettings[props.tableKey];

  return (
    <ModalTrigger label={'Edit Table Settings'} button={<FontAwesomeIcon icon={['fas', 'gear']} />}>
      {(close) => (
        <Dialog
          onClose={() => {
            props.undoChanges();
            close();
          }}
          title={
            <FormattedMessage
              defaultMessage={'Account Table Columns'}
              description={'Header for section that contains settings to alter which columns are visible for the account table'}
            />
          }
        >
          <TableSettingsControls settings={props.settings} setSettings={props.setSettings} settingsKey={props.tableKey} undoChanges={props.undoChanges} />
          <SettingsButtons
            onSave={async () => {
              await props.saveChanges(close);
            }}
            onResetToDefault={() => props.setSettings({ [props.tableKey]: defaultSettings })}
            onCancel={() => {
              props.undoChanges();
              close();
            }}
          />
        </Dialog>
      )}
    </ModalTrigger>
  );
}

export default TableSettingsModalTrigger;
